import React, {useState} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {getText} from 'helpers/language-helper';
import {exportForPlatform} from 'helpers/export-helper';

import {adminUiTexts} from 'data/ui-texts/admin-ui-texts';
import {facilitatorUiTexts} from 'data/ui-texts/facilitator-ui-texts';
import Button from 'components/ui/button/button';
import AdminHeader from './admin-header/admin-header';
import AdminGames from './admin-games/admin-games';
import AdminSurveysPopup from './admin-surveys-popup/admin-surveys-popup';
import AdminGamePopup from './admin-game-popup/admin-game-popup';
import AdminGameStatsPopup from './admin-game-stats.popup/admin-game-stats-popup';
import AdminAddFacilitatorPopup from './admin-add-facilitator-popup/admin-add-facilitator-popup';
import AdminRemoveFacilitatorPopup from './admin-remove-facilitator-popup/admin-remove-facilitator-popup';
import AdminAddGamePopup from './admin-add-game-popup/admin-add-game-popup';
import AdminDeleteGamePopup from './admin-delete-game-popup/admin-delete-game-popup';
import AdminDownloadPlayerProgress from './admin-download-player-progress/admin-download-player-progress';
import AdminTextToSpeechPopup from './admin-text-to-speech-popup/admin-text-to-speech-popup';
import AdminExportModulePopup from './admin-export-module-popup/admin-export-module-popup';
import './admin.scss';

const Admin = (props) => {
	const {
		languageId,
		sortProperty,
		sortDirection,
		userData,
		surveyResponses,
		gamesData,
		usersData,
		playersData,
		sortGamesByProperty,
		updateGameData,
		addFacilitatorToGame,
		removeFacilitatorFromGame,
		createGame, 
		deleteGame,
		goToPage,
		handleLogout
	} = props;

	/* Show surveys popup */
	const [showSurveysPopup, setShowSurveysPopup] = useState(false);

	/* Select game (shows popup with info or stats about game or all games) */
	const [selectedGameId, setSelectedGameId] = useState(null);
	const [showGameStats, setShowGameStats] = useState(null);
	const [showFullStats, setShowFullStats] = useState(false);
	const handleSelectGame = (gameId = null) => {
		setSelectedGameId(gameId);
		if (!gameId) setShowGameStats(false);
	};
	const handleToggleGameStats = (gameId = null) => {
		setSelectedGameId(gameId);
		if (!gameId) {
			setShowGameStats(false);
			setShowFullStats(false);
		} else {
			setShowGameStats(true);
			setShowFullStats(false);
		}
	};

	/* Export for platform */
	const [isExporting, setIsExporting] = useState(false);
	const handleExportForPlatform = () => {
		if (isExporting) return;
	
		exportForPlatform(playersData, gamesData, surveyResponses).then(() => {
			setIsExporting(false);
		});
	};
	
	/* Add facilitator to game (popup) */
	const [showAddFacilitatorPopup, setShowAddFacilitatorPopup] = useState(false);
	const toggleShowAddFacilitatorPopup = (show = false) => {
		setShowAddFacilitatorPopup(show);
	};

	/* Remove facilitator from game (popup) */
	const [removeFacilitatorEmail, setRemoveFacilitatorEmail] = useState(null);
	const toggleRemoveFacilitatorPopup = (email = null) => {
		setRemoveFacilitatorEmail(email);
	};

	/* Add new game (popup) */
	const [showAddGamePopup, setShowAddGamePopup] = useState(false);
	const toggleShowAddGamePopup = (show = false) => {
		setShowAddGamePopup(show);
	};

	/* Delete game (popup) */
	const [showDeleteGamePopup, setShowDeleteGamePopup] = useState(null);
	const toggleShowDeleteGamePopup = (show = false) => {
		setShowDeleteGamePopup(show);
	};

	/* Text to speech (popup) */
	const [showTextToSpeechPopup, setShowTextToSpeechPopup] = useState(false);
	const toggleTextToSpeechPopup = () => {
		setShowTextToSpeechPopup(!showTextToSpeechPopup);
	};


	/* Export modules (popup) */
	const [showExportModulePopup, setShowExportModulePopup] = useState(false);
	const toggleExportModulePopup = () => {
		setShowExportModulePopup(!showExportModulePopup);
	};

	return (
		<div className="Admin">
			<div className="Admin-header">
				<AdminHeader 
					languageId={languageId}
					userData={userData}
					goToPage={goToPage}
					handleLogout={handleLogout}
				/>
			</div>
			<div className="Admin-content">
				{/* Button: Add new game */}
				<div className="Admin-addGameBtn">
					<Button 
						text={getText(adminUiTexts.createNewGame, languageId)} 
						classes={['facilitator']} 
						onClick={() => {toggleShowAddGamePopup(true);}}
					/>
				</div>

				{/* {appConfig.env === 'development' &&
					<div className='Admin-exportModulesButton'>
						<Button
							classes={['facilitator']}
							text={getText(adminUiTexts.exportModuleData, languageId)}
							onClick={() => {toggleExportModulePopup();}}
						/>
					</div>
				} */}

				{/* Text to speech btn */}
				{appConfig.env !== 'demo' &&
					<div className="Admin-textToSpeechBtn">
						<Button 
							classes={['facilitator']} 
							text={getText(adminUiTexts.textToSpeechPopup.title, languageId)} 
							onClick={() => {toggleTextToSpeechPopup();}} 
						/>
					</div>
				}

				{/* Export for platform */}
				<div className="Admin-exportBtn">
					<Button 
						isLoading={isExporting}
						classes={['facilitator']} 
						text={getText(adminUiTexts.export, languageId)} 
						onClick={() => {handleExportForPlatform();}} 
					/>
				</div>


				{/* Show survey popup btn */}
				<div className="Admin-surveyPopupBtn">
					<Button 
						classes={['facilitator']} 
						text={getText(facilitatorUiTexts.viewSurveys, languageId)} 
						onClick={() => {setShowSurveysPopup(true);}} 
					/>
				</div>

				{/* Button: download player progress */ }
				<div className="Admin-downloadPlayerProgressBtn">
					<AdminDownloadPlayerProgress 
						languageId={languageId}
						games={gamesData} 
						players={playersData}
					/>
				</div>

				{/* Button: View stats for all games */}
				<div className="Admin-viewStatsBtn">
					<Button 
						text={getText(adminUiTexts.viewFullGameStats, languageId)} 
						classes={['facilitator']} 
						onClick={() => {setShowFullStats(true);}}
					/>
				</div>
				{/* List of games */}
				<AdminGames 
					languageId={languageId}
					sortProperty={sortProperty}
					sortDirection={sortDirection}
					gamesData={gamesData}
					playersData={playersData}
					sortGamesByProperty={sortGamesByProperty}
					handleSelectGame={handleSelectGame}
					handleToggleGameStats={handleToggleGameStats}
				/>
			</div>

			{showExportModulePopup && <AdminExportModulePopup
				languageId={languageId}
				togglePopup={toggleExportModulePopup}
			/>}

			{showSurveysPopup && <AdminSurveysPopup 
				languageId={languageId}
				gamesData={gamesData}
				surveyResponses={surveyResponses}
				togglePopup={setShowSurveysPopup}
			/>}

			{/* Popup: Show stats of all games */}
			{showFullStats && <AdminGameStatsPopup 
				languageId={languageId}
				gameId={null} 
				gamesData={gamesData}
				playersData={playersData}
				handleToggleGameStats={handleToggleGameStats}
			/>}

			{/* Popup: Show stats for selected game */}
			{(selectedGameId && showGameStats) &&
				<AdminGameStatsPopup 
					languageId={languageId}
					gameId={selectedGameId} 
					gamesData={gamesData}
					playersData={playersData}
					handleToggleGameStats={handleToggleGameStats}
				/>
			}
			
			{/* Popup: Show info for selected game */}
			{(selectedGameId && !showGameStats) &&
				<AdminGamePopup 
					languageId={languageId}
					gameId={selectedGameId} 
					gamesData={gamesData}
					usersData={usersData}
					handleSelectGame={handleSelectGame}
					updateGameData={updateGameData}
					toggleShowAddFacilitatorPopup={toggleShowAddFacilitatorPopup}
					toggleRemoveFacilitatorPopup={toggleRemoveFacilitatorPopup}				
					toggleShowDeleteGamePopup={toggleShowDeleteGamePopup}	
				/>
			}
			{(selectedGameId && showAddFacilitatorPopup) && 
				<AdminAddFacilitatorPopup 
					languageId={languageId}
					gameId={selectedGameId} 
					togglePopup={toggleShowAddFacilitatorPopup}
					addFacilitatorToGame={addFacilitatorToGame}
				/>
			}
			{(selectedGameId && removeFacilitatorEmail) &&
				<AdminRemoveFacilitatorPopup 
					languageId={languageId}
					gameId={selectedGameId}
					email={removeFacilitatorEmail}
					usersData={usersData}
					toggleRemoveFacilitatorPopup={toggleRemoveFacilitatorPopup}
					removeFacilitatorFromGame={removeFacilitatorFromGame}
				/>
			}
			{showAddGamePopup && 
				<AdminAddGamePopup 
					languageId={languageId}
					gamesData={gamesData}
					createGame={createGame}
					togglePopup={toggleShowAddGamePopup}
				/>
			}
			{(selectedGameId && showDeleteGamePopup) && 
				<AdminDeleteGamePopup 
					languageId={languageId}
					gameId={selectedGameId}
					gamesData={gamesData}
					toggleShowDeleteGamePopup={toggleShowDeleteGamePopup}
					deleteGame={deleteGame}
					setSelectedGameId={setSelectedGameId}
				/>
			}

			{showTextToSpeechPopup && 
				<AdminTextToSpeechPopup 
					languageId={languageId}
					togglePopup={toggleTextToSpeechPopup}
				/>
			}
		</div>
	);

};

Admin.propTypes = {
	languageId: PropTypes.string.isRequired,
	sortProperty: PropTypes.string.isRequired,
	sortDirection: PropTypes.string.isRequired,
	userData: PropTypes.object.isRequired,
	gamesData: PropTypes.array.isRequired,
	usersData: PropTypes.array.isRequired,
	surveyResponses: PropTypes.array.isRequired,
	playersData: PropTypes.array.isRequired,
	sortGamesByProperty: PropTypes.func.isRequired,
	updateGameData: PropTypes.func.isRequired,
	addFacilitatorToGame: PropTypes.func.isRequired,
	removeFacilitatorFromGame: PropTypes.func.isRequired,
	createGame: PropTypes.func.isRequired,
	deleteGame: PropTypes.func.isRequired,
	goToPage: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default Admin;
