import dayjs from 'dayjs';
import {getModuleMaxPoints, getNumberOfFilledStars} from 'helpers/points-helper';
import {checkIfModuleGroupIsCompleted, getModuleGroupCompletedDate} from './module-helper';
import {scenariosData} from 'data/scenarios-data';
import appConfig from 'config/app.config';

/**
 * Export for platform
 * @returns {promise}
 */
const exportForPlatform = (playersData, gamesData, surveyResponses) => {
	return new Promise((resolve)=>{
		/* Get game content */
		const content = getContent();

		/* Get players */
		const players = getPlayers(playersData, gamesData);

		/* Get games */
		const games = getGames(gamesData);

		/* Create download file obj */
		const fileObj = {
			downloadDate: dayjs(new Date()).format('YYYY-MM-DD'),
			env: appConfig.env,
			project: 'almondy',
			scenarios: content,
			players,
			games,
			surveys: surveyResponses
		};


		/* Split into multiple files */
		const stepSize = 500.;
		const steps = Math.ceil(players.length / stepSize);
		console.log(players.length);
		for (let stepIndex = 0; stepIndex < steps; stepIndex++) {
			const playersInStep = players.slice(stepIndex * stepSize, stepIndex * stepSize + stepSize);
			console.log(playersInStep.length);
			fileObj.players = playersInStep;

			const fileJson = JSON.stringify(fileObj, null, 4);
			const fileBlob = new Blob([fileJson], {type: 'text/json'});
			const fileName = 'almondy-' + appConfig.env + '-' + fileObj.downloadDate + '-' + stepIndex + '.json';
			const fileUrl = window.URL.createObjectURL(new Blob([fileBlob]));
			const fileLink = document.createElement('a'); 
			fileLink.href = fileUrl;
			fileLink.setAttribute('download', fileName);
			document.body.appendChild(fileLink);
			fileLink.click();
			fileLink.parentNode.removeChild(fileLink);
		}

		resolve({status: 'success', content, players});
	});
};


/**
 * Get game content ids (scenario, module groups, modules, tasks)
 * @returns {array} content
 */
const getContent = () => {
	const content = [];

	/* Loop over scenarios */
	scenariosData.forEach((scenario) => {
		const scenarioObj = {id: scenario.id, moduleGroups: []};
		if (scenario.moduleGroups && scenario.modulesData) {
			/* Loop over module groups */
			scenario.moduleGroups.forEach((moduleGroup) => {
				const moduleGroupObj = {id: moduleGroup.id, modules: []};
				if (moduleGroup.moduleIds) {
					/* Loop over modules */
					moduleGroup.moduleIds.forEach((moduleId) => {
						const moduleData = scenario.modulesData.find((module) => {return module.id === moduleId;});
						if (moduleData) {
							const moduleObj = {id: moduleId, tasks: []};
							if (moduleData.tasks) {
								/* Loop over tasks */
								moduleData.tasks.forEach((task) => {
									const taskObj = {id: task.id, taskId: task.taskId, type: task.type};

									/* Update task types */
									if (task.type === 'multiple-choice') {
										taskObj.type = 'mc';
									} else if (task.type === 'spot-errors') {
										taskObj.type = 'spot-items';
									}

									/* Handle task types */
									if ((taskObj.type === 'mc' || taskObj.type === 'survey')) {
										if (task.options) {
											taskObj.options = [];
											task.options.forEach((option) => {
												taskObj.options.push({id: option.id});
											});
										}
									} else if (taskObj.type === 'spot-items') {
										taskObj.items = [];
										if (task.errors) {
											task.errors.forEach((error) => {
												const itemObj = {id: error.id};
												taskObj.items.push(itemObj);
											});
										}
									} else if (
										taskObj.type === 'order' || 
										taskObj.type === 'sort' ||
										taskObj.type === 'match'
									) {
										taskObj.items = [];
										taskObj.categories = [];
										if (task.items) {
											task.items.forEach((item) => {
												const itemObj = {id: item.id};
												if (taskObj.type === 'sort') {
													itemObj.categoryIds = (item.categoryIds ? item.categoryIds : []);
												}
												taskObj.items.push(itemObj);
											});
										}
										if (task.categories) {
											task.categories.forEach((category) => {
												if (taskObj.type === 'sort') {
													taskObj.categories.push({id: category.id});
												} else if (taskObj.type === 'match') {
													taskObj.categories.push(
														{id: category.id, itemIds: category.itemIds}
													);
												}
											});
										}
									} else {
										if (taskObj.type !== 'instructions') {
											console.error('cannot handle task of type ' + taskObj.type);
										}
									}

									/* Special case, task was change from mc image to spot items */
									if (
										task.taskId === 's1-m2-mc-when-moving-around-IFF' ||
										task.taskId === 's1-m4-mc-when-moving-around-IFF'
									) {
										delete taskObj.options;
										taskObj.type = 'spot-items';
										taskObj.items = task.options.map((option) => {
											return {id: option.id};
										});
									}

									/* Special cases, fix duplicate task ids */
									if (moduleId === 's1-m3' && task.taskId === 's1-m1-mc-bring-your-phone') {
										taskObj.taskId = 's1-m3-mc-bring-your-phone';
									}
									if (moduleId === 's1-m4' && task.taskId === 's1-m2-mc-haccp-document') {
										taskObj.taskId = 's1-m4-mc-haccp-document';
									}
									moduleObj.tasks.push(taskObj);
								});
							}
							/* Add module */
							moduleGroupObj.modules.push(moduleObj);
						}
					});
				}
				/* Add module group */
				scenarioObj.moduleGroups.push(moduleGroupObj);
			});
		}
		/* Add scenario */
		content.push(scenarioObj);
	});

	return content;
};

/**
 * Get players
 * @param {array} playersData 
 */
const getPlayers = (playersData, gamesData) => {
	const players = [];

	/* Loop over players */
	playersData.forEach((player) => {
		const playerObj = {id: player.id, created: player.created, gameId: player.gameId, modules: []};
		if (player.languageId) {
			playerObj.languageId = player.languageId;
		}
		if (player.modules && player.modules.length > 0) {
			/* Get module groups data */
			const gameData = gamesData.find((game) => {return game.id === player.gameId;});
			const scenarioId = (gameData && gameData.scenarioId ? gameData.scenarioId : 'scenario1');
			const scenarioData = scenariosData.find((scenario) => {return scenario.id === scenarioId;});
			if (scenarioData && scenarioData.moduleGroups) {
				scenarioData.moduleGroups.forEach((moduleGroup) => {
					const isCompleted = checkIfModuleGroupIsCompleted(moduleGroup.moduleIds, player);
					if (isCompleted) {
						const completedDate = getModuleGroupCompletedDate(moduleGroup.moduleIds, player);
						if (!playerObj.hasOwnProperty('moduleGroups')) {
							playerObj.moduleGroups = [];
						}
						playerObj.moduleGroups.push({
							moduleGroupId: moduleGroup.id,
							passedDates: [completedDate]
						});
					}
				});
			}

			/* Loop over player modules */
			player.modules.forEach((playerModule) => {
				const playerModuleObj = {
					moduleId: playerModule.moduleId,
					maxPoints: (playerModule.maxPoints ? playerModule.maxPoints : 0),
					maxStars: (playerModule.maxStars ? playerModule.maxStars : 0),
					sessions: []
				};
				if (playerModule.completedDate) {
					playerModuleObj.passedDate = playerModule.completedDate;
				}

				if (playerModule.sessions && playerModule.sessions.length > 0) {
					/* Loop over player sessions */
					playerModule.sessions.forEach((playerSession) => {
						const playerSessionObj = {
							currentTaskId: (playerSession.currentTaskId ? playerSession.currentTaskId : null),
							isCompleted: (playerSession.isCompleted ? playerSession.isCompleted : false),
							milisecondsPlayed: (playerSession.milisecondsPlayed ? playerSession.milisecondsPlayed : 0),
							points: (playerSession.points ? playerSession.points : 0),
							stars: 0,
							startedDated: (playerSession.started ? playerSession.started : null),
							tasks: []
						};
						/* Calculate stars (not saved in player data) */
						if (playerSessionObj.points > 0) {
							const maxPoints = getModuleMaxPoints(playerModule.moduleId);
							const filledStars = getNumberOfFilledStars(playerSessionObj.points, maxPoints);
							if (filledStars > 0) {
								playerSessionObj.stars = filledStars;
							}
						}

						if (playerSession.tasks && playerSession.tasks.length > 0) {
							/* Loop over player tasks */
							playerSession.tasks.forEach((playerTask) => {
								const playerTaskObj = {
									type: playerTask.type,
									taskId: playerTask.taskId,
									errors: (playerTask.errors ? playerTask.errors : 0),
									points: (playerTask.points ? playerTask.points : 0),
									isCompleted: (playerTask.isCompleted ? playerTask.isCompleted : false),
								};

								/* Update task types */
								if (playerTask.type === 'multiple-choice') {
									playerTaskObj.type = 'mc';
								} else if (playerTask.type === 'spot-errors') {
									playerTaskObj.type = 'spot-items';
								}

								/* Handle task types */
								if (playerTaskObj.type === 'mc') {
									playerTaskObj.selectedOptionIds = 
										(playerTask.selectedOptionIds ? playerTask.selectedOptionIds : []);
								} else if (playerTaskObj.type === 'spot-items') {
									if (playerTask.selectedItemIds) {
										playerTaskObj.selectedItemIds = playerTask.selectedItemIds;
									}
								} else if (playerTask.type === 'order') {
									if (playerTask.orderedItems) {
										playerTaskObj.orderedItems = playerTask.orderedItems.map((item) => {
											const itemObj = JSON.parse(JSON.stringify(item));
											if (playerTaskObj.isCompleted) {
												itemObj.isLocked = true;
											}
											return itemObj;
										});
									}
								} else if (playerTask.type === 'sort') {
									if (playerTask.sortedItems) {
										playerTaskObj.sortedItems = playerTask.sortedItems;
									}
								} else if (playerTask.type === 'match') {
									if (playerTask.matchedItemIds) {
										playerTaskObj.matchedItemIds = playerTask.matchedItemIds;
									}
								} else {
									if (playerTask.type !== 'survey') {
										console.error('cannot handle player task of type ' + playerTask.type);
									}
								}

								/* Special case, task was change from mc image to spot items */
								if (
									playerTask.taskId === 's1-m2-mc-when-moving-around-IFF' ||
									playerTask.taskId === 's1-m4-mc-when-moving-around-IFF'
								) {
									playerTaskObj.selectedItemIds = 
										JSON.parse(JSON.stringify(playerTaskObj.selectedOptionIds));
									delete playerTaskObj.selectedOptionIds;
								}

								/* Special cases, fix duplicate task ids */
								if (
									playerModule.moduleId === 's1-m3' && 
									playerTask.taskId === 's1-m1-mc-bring-your-phone'
								) {
									playerTaskObj.taskId = 's1-m3-mc-bring-your-phone';
								}
								if (
									playerModule.moduleId === 's1-m4' && 
									playerTask.taskId === 's1-m2-mc-haccp-document'
								) {
									playerTaskObj.taskId = 's1-m4-mc-haccp-document';
								}
								
								/* Add task */
								playerSessionObj.tasks.push(playerTaskObj);
							});
						}
						/* Add session */
						playerModuleObj.sessions.push(playerSessionObj);
					});
				};
				/* Add player module */
				playerObj.modules.push(playerModuleObj);
			});
		}
		/* Add player */
		players.push(playerObj);
	});

	return players;
};

/**
 * Get games
 * @param {array} gamesData 
 * @returns {array} surveys
 */
const getGames = (gamesData) => {
	const games = gamesData.map((game) => {
		return {id: game.id, name: game.name, scenarioId: game.scenarioId};
	});
	return games;
};


export {
	exportForPlatform
};