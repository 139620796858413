const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-retraining-welcome',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `Välkommen till den första delen av hygienutbildningen. Jag är din chef och kommer att hjälpa dig genom spelet.`,
			en: `Welcome to the first part of the hygiene training. I'm your manager, and I'll help you through the game. `,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-retraining-welcome-2',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `Utbildningen handlar om vad du kan göra för att hålla en hög hygienstandard. Målet är att ingen ska bli sjuk eller skadas av att äta våra tårtor.`,
			en: `The training is all about what you can do to maintain high hygiene standards. The goal is that noone should get sick or hurt from eating our pies. `,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-solving-tasks',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `I det här spelet får du poäng genom att lösa uppgifter. Ju färre fel du gör, desto mer poäng får du.`,
			en: `In this game you'll get points for solving tasks. The fewer mistakes you make, the more points you get.`,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-getting-stars',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			se: `När du samlar poäng får du också stjärnor. Högst upp på skärmen ser du hur många poäng och stjärnor du har.`,
			en: `As you get points, you'll also gain stars. At the top of your screen, you can see how many points and stars, you have.`,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-advancing',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `Du behöver tre stjärnor för att gå vidare till nästa modul.`,
			en: `You need to get three stars to advance to the next module. `,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-how-to-dress',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `Den här modulen handlar om hur man tar på sig arbetskläder och förbereder sig innan man går in i produktionen. I nästa modul går vi igenom ytterligare rutiner och krav som gäller när vi tillverkar våra produkter.`,
			en: `This module will be about how to dress and prepare before entering production areas. In the next module we'll dive deeper into other specific demands when making our products. `,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-first-task',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			se: `Den första uppgiften handlar i vilken ordning du ska ta på dig arbetskläderna.`,
			en: `The first task is about how to get dressed properly. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-hair-beard-snood',
		layout: 's1-m1-hair-snoods',
		subtype: 'images',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			se: `Vilket är rätt sätt att använda hårnät och skäggskydd? Klicka på rätt bilder.`,
			en: `What is the correct way to use hairnet and beard snood? Click on the correct images.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `När man jobbar på lagret kan man använda en av våra mössor istället för hårnät.`,
								en: `When working in the warehouse, you can use one of our caps instead of a hairnet. `
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-good-hygiene-locker-room',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `God hygien börjar i omklädningsrummet. Vi går dit nu.`,
			en: `Good hygiene begins in the locker room. Let's go there now. `,
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m3-spot-errors-dressing-room-locker-jewellry',
		layout: 's1-m3-dressing-room-locker-jewellry',
		background: 'dressing-room',
		text: {
			se: `De flesta typer av smycken är inte tillåtna i produktionen eller på lagret. Klicka på de 4 saker som inte är tillåtna. Klicka på OK när du är klar eller om du inte kan hitta alla.`,
			en: `Most kinds of jewellery are not allowed in production areas or in the warehouse. Click on the 4 things that are not allowed. Click ok when you are ready or if you can't find them all. `
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'Nose-piercing'
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'Watch',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'Necklace',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'Wedding-ring',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-private-cellphones',
		background: 'dressing-room',
		text: {
			se: `Privata mobiltelefoner då, är de tillåtna?`,
			en: `How about private cellphones, are they allowed?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `Nej`,
					en: `No`,
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Ja`,
					en: `Yes`,
				},		
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Bara om de har säkerhetsglas`,
					en: `Only if they have safety glass`,
				},		
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-private-clothes-locker',
		background: 'dressing-room',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			se: `I omklädningsrummet har du ett skåp för dina privata kläder och ett skåp för dina arbetskläder. Det är viktigt att ha rätt saker på rätt plats.`,
			en: `In the locker room there's a locker for your private clothes and a locker for your work clothes. It's important to put things in the right places. `,
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m3-spot-errors-dressing-room-locker',
		layout: 's1-m1-dressing-room-locker',
		background: 'dressing-room',
		text: {
			se: `Klicka på 3 saker som är fel på bilden. Klicka på OK när du är klar eller om du inte kan hitta alla.`,
			en: `Click on the 3 things that are wrong on the image. Click OK when you are ready or if you can't find them all. `
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'shoes',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Ja, ställ dem i hyllorna för privata skor.`,
								en: `Yes. Place those on the shelves for private shoes. `
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'private-shirt'
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hairnet',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Ja, den här ska bara slängas efter användning.`,
								en: `Yes, this should just be tossed after use.`
							}
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-remember-responsibility',
		background: 'dressing-room',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			se: `Kom ihåg! Det är ditt eget ansvar att hålla ordning i omklädningsrummet och att lägga dina använda arbetskläder i tvätten.`,
			en: `Remember, it's your responsibility to keep your lockers clean and neat, and to put clothes in the laundry. `,
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m3-order-hygiene-sluice',
		background: 'dressing-room',
		text: {
			se: `I omklädningsrummet tar du på dig dina arbetskläder. I vilken ordning tar man på sig arbetskläderna på rätt sätt?
			<br /><br />
			Sätt stegen i rätt ordning! Klicka sedan på OK.`,
			en: `Put on your work clothes in the locker room. What is the correct order to get dressed in?
			<br /><br />
			Place the actions below in order. Then click OK.`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					se: `Tvätta händerna`,
					en: `Wash hands`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					se: `Hårnät, skäggskydd och arbetsbyxor`,
					en: `Pants, beard cover and hairnet`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					se: `Arbetsrock`,
					en: `Work coat`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					se: `Arbetsskor`,
					en: `Work shoes`
				},
				correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5,
				text: {
					se: `Hygiensluss`,
					en: `Hygiene sluice`
				},
				correctOrderNumbers: [5]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-remember-work-shoes',
		background: 'dressing-room',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `Kom ihåg att ställa dina privata inneskor i hyllan utanför skoslussen.`,
			en: `Remember to place your private shoes on a shelf outside the shoe sluice.`,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-alright',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `Okej, nu tror jag att vi snart är redo att gå ner i produktionen. Men först ska du gå igenom en "hygiensluss".`,
			en: `Alright, I think we're almost ready to enter the production areas. But first you must pass through a hygiene sluice. `,
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m3-order-wash-hands',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			se: `Innan du går in i produktionen måste du alltid tvätta händerna. I vilken ordning tvättar du händerna på rätt sätt? `,
			en: `Before entering production areas, you must always wash your hands. What is the correct order to do the following actions in?`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					se: `Tvätta händerna noga med tvål och vatten`,
					en: `Wash hands with soap and water`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					se: `Torka händerna med papper`,
					en: `Dry hands with paper towels`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					se: `Släng pappret i papperskorgen`,
					en: `Put paper towels in bin`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					se: `Använda handsprit`,
					en: `Use sanitizer`
				},
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-nails',
		layout: 's1-m1-nails',
		subtype: 'images',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			se: `Naglarna är en viktig del av handhygienen. Klicka på bilderna som är fel.`,
			en: `The nails are an important part of hand hygiene. Click the incorrect images.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Short and clean nail
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Nail with nail polish
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Short, dirty nail
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Long, dirty nail
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Artificial nail
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-washing-hands',
		layout: 's1-m1-washing-hands',
		subtype: 'images',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			se: `Tre områden är särskilt lätta att glömma när man tvättar händerna. Vilka är det?`,
			en: `Three areas are especially easy to forget, when washing hands. What are they?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Palm
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Back of hand
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Nails
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Between fingers
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Thumb
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Pinkie
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, // Wrist
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-what-clothes',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `Vilka kläder man ska ha på sig beror på vilken zon man jobbar i.`,
			en: `What clothes you should wear depends on the zone you're working in. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-production-person',
		layout: 's1-m1-production-person',
		subtype: 'images',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			se: `Vilket av följande ska man ha på sig i den VITA zonen om man är produktionspersonal eller operatör?`,
			en: `If you are production staff or operator, which of the following should you be wearing in a WHITE zone?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Image of blue outfit
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Image of white outfit
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Image of obviously private outfit
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Spacesuit
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-when-washing-hands',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		shuffleOptions: false,
		showRequiredNumberOfAnswers: false,
		text: {
			se: `Efter vilka situationer måste man komma ihåg att tvätta händerna?
			<br /><br /><b>Välj 1 – och tänk på att läsa alla alternativen:</b>`,
			en: `After which situations must you remember to wash your hands?
			<br /><br /><b>Choose 1 - and remember to read all the options:</b>`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `När man går in i produktionen (vit zon)`,
					en: 'When entering production areas',
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Efter raster`,
					en: 'After breaks',
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Vid byte av arbetsstation`,
					en: 'When changing work station',
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Efter att ha gått på toa`,
					en: 'After being to the toilet',
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					se: `I alla ovanstående situationer`,
					en: 'In all above situations',
				},
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-scratch-wound',
		layout: 's1-m1-scratch-wound',
		subtype: 'images',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			se: `Om man har eller får ett sår, vad ska man då använda?`,
			en: `If you have a scratch or wound, what should you use?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Blue band aid
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Precis! Våra blåa plåster är lätta att upptäcka och detekterbara i metalldetektorn. Du ska även bära handske om du har plåstret på handen.`,
								en: `Exactly! Our blue band aids are detectable in the metal detector. And you should also put on a glove when wearing a band aid.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Normal band aid
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Cast
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Gauze
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m3-spot-errors-worker-issues',
		layout: 's1-m1-worker-issues',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			se: `Se om du kan finna 4 fel innan vi går in i produktionen. Klicka på OK när du har funnit dem eller om du inte kan hitta alla.`,
			en: `Before we go inside the production area, see if you can spot the 4 issues. Click OK when you have them, or if you can't find them all.`
		},
		errors: [					 		
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'clothe-hole', // Hole in clothes with thread
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'shirt-dirty' // Shirt is dirty
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'chocolate', // Chocolate bar sticking out of pocket
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Snyggt! Man får inte ha med sig mat i produktionsområdena.`,
								en: `Nice catch! You are not allowed to bring foodstuffs into production areas.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hair-out', // Hair sticking out under hairnet
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'tshirt', // t-shit showing behind coat
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-what-clothes',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `Det var det, nu är du klar med den första modulen. Om du har tre stjärnor kan du fortsätta till nästa modul, annars måste du göra om den här modulen.`,
			en: `That's it, you've completed the first module. If you have three stars, you can continue to the next module. If not, you'll have to complete this module once more. `,
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m3-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};